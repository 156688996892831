a.scroll-up {
    cursor: pointer;
    margin: @button-margin;    
    display:inline-block;    
    width: @button-width;
    height: @button-height;
    background-color: @background-color;               
    transition: opacity 0.100s ease-in-out;
    pointer-events: auto;
    
    
    -webkit-box-shadow: 2px 10px 15px 4px rgba(170,170,170,0.35);
-moz-box-shadow: 2px 10px 15px 4px rgba(170,170,170,0.35);
box-shadow: 2px 10px 15px 4px rgba(170,170,170,0.35);
 

    &:before {   
        content: ""; 
        display:inline-block;
        padding: @icon-padding;
        background: #707070 url(../img/arrow-up.svg) no-repeat center center;
        width: inherit;
        height: inherit;
        background-size: @icon-width @icon-height;        
    }
    &:after {
        display:block;
        padding:4px;
        border:1px solid #707070;
        line-height: 1.2em;
        font-size: 0.8em;
        content: attr(data-text); /*"BACK\ATO\ATOP";*/
        background-color: #FFFFFF;
        margin:-5px 0;
        text-align:center
    }
}

.scroll-up-hidden {
    opacity: 0;
}