div.scroll-up {
  width: 100%;
  max-width: 1000px;
  text-align: right;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 10%;
  pointer-events: none;
}
a.scroll-up {
  cursor: pointer;
  margin: 0px;
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: #373737;
  transition: opacity 0.1s ease-in-out;
  pointer-events: auto;
  -webkit-box-shadow: 2px 10px 15px 4px rgba(170, 170, 170, 0.35);
  -moz-box-shadow: 2px 10px 15px 4px rgba(170, 170, 170, 0.35);
  box-shadow: 2px 10px 15px 4px rgba(170, 170, 170, 0.35);
}
a.scroll-up:before {
  content: "";
  display: inline-block;
  padding: 0px;
  background: #707070 url(../img/arrow-up.svg) no-repeat center center;
  width: inherit;
  height: inherit;
  background-size: 36px 36px;
}
a.scroll-up:after {
  display: block;
  padding: 4px;
  border: 1px solid #707070;
  line-height: 1.2em;
  font-size: 0.8em;
  content: attr(data-text);
  /*"BACK\ATO\ATOP";*/
  background-color: #FFFFFF;
  margin: -5px 0;
  text-align: center;
}
.scroll-up-hidden {
  opacity: 0;
}
