div.scroll-up {
    width: 100%;    
    max-width: @container-width;
    text-align: right;
    position: fixed;
    left: 0;
    right:0;
    margin: 0 auto;    
    bottom: 10%;    
    pointer-events: none;
}